"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var versionParts = react_1.version.split('.');
var hasStaticContext = Number(versionParts[0]) >= 16 && Number(versionParts[1]) >= 6;
var hasHooks = Number(versionParts[0]) >= 16 && Number(versionParts[1]) >= 7;
var Lifecycle;
(function (Lifecycle) {
    Lifecycle[Lifecycle["Mount"] = 0] = "Mount";
    Lifecycle[Lifecycle["Update"] = 1] = "Update";
    Lifecycle[Lifecycle["Unmount"] = 2] = "Unmount";
})(Lifecycle || (Lifecycle = {}));
var through = function (fallback) { return function (node) {
    if (node instanceof Error) {
        throw node;
    }
    return node || fallback;
}; };
var consumerError = new Error('Attempted to render a <Consumer /> without an <Anchor />');
var providerError = function (node) {
    throw new Error('Attempted to render a <Provider /> without an <Anchor />');
};
var helpMessage = ' Jumpgate. Did you render multiple <Provider /> ?';
var mountError = 'Tried to use an already-full' + helpMessage;
var updateError = 'Tried to update an empty' + helpMessage;
var unmountError = 'Tried to clear an empty' + helpMessage;
var createAnchor;
var createConsumer;
var createProvider;
if (hasHooks) {
    createAnchor = function (_a, _b) {
        var NodeProvider = _a.Provider;
        var RenderProvider = _b.Provider;
        return function Anchor(_a) {
            var children = _a.children;
            var _b = react_1.useState(null), node = _b[0], setState = _b[1];
            var setNode = react_1.useCallback(function (node, lifecycle) {
                setState(function (state) {
                    if (lifecycle === Lifecycle.Mount && state !== null) {
                        console.warn(mountError);
                    }
                    if (lifecycle === Lifecycle.Update && state === null) {
                        console.warn(updateError);
                    }
                    if (lifecycle === Lifecycle.Unmount && state === null) {
                        console.warn(unmountError);
                    }
                    return node;
                });
            }, [setState]);
            return (react_1.default.createElement(RenderProvider, { value: setNode },
                react_1.default.createElement(NodeProvider, { value: node }, children)));
        };
    };
    createConsumer = function (Context) {
        return function Consumer(_a) {
            var children = _a.children;
            var node = react_1.useContext(Context);
            return through(children)(node) || null;
        };
    };
    createProvider = function (Context) {
        return function Provider(_a) {
            var children = _a.children;
            var render = react_1.useContext(Context);
            var isMounted = react_1.useRef(false);
            react_1.useMemo(function () {
                render(children, isMounted.current ? Lifecycle.Update : Lifecycle.Mount);
                isMounted.current = true;
            }, [children, render]);
            react_1.useEffect(function () { return function () {
                render(null, Lifecycle.Unmount);
                isMounted.current = false;
            }; }, []);
            return null;
        };
    };
}
else {
    createAnchor = function (_a, _b) {
        var NodeProvider = _a.Provider;
        var RenderProvider = _b.Provider;
        return /** @class */ (function (_super) {
            __extends(Anchor, _super);
            function Anchor() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.state = {
                    node: null,
                };
                _this.setNode = function (node, lifecycle) {
                    _this.setState(function (state) {
                        if (lifecycle === Lifecycle.Mount && state.node !== null) {
                            console.warn(mountError);
                        }
                        if (lifecycle === Lifecycle.Update && state.node === null) {
                            console.warn(updateError);
                        }
                        if (lifecycle === Lifecycle.Unmount &&
                            state.node === null) {
                            console.warn(unmountError);
                        }
                        return { node: node };
                    });
                };
                return _this;
            }
            Anchor.prototype.render = function () {
                return (react_1.default.createElement(RenderProvider, { value: this.setNode },
                    react_1.default.createElement(NodeProvider, { value: this.state.node }, this.props.children)));
            };
            return Anchor;
        }(react_1.PureComponent));
    };
    createConsumer = function (_a) {
        var Consumer = _a.Consumer;
        return function (_a) {
            var children = _a.children;
            return (react_1.default.createElement(Consumer, null, through(children)));
        };
    };
    if (hasStaticContext) {
        createProvider = function (RenderContext) { var _a; return _a = /** @class */ (function (_super) {
                __extends(Provider, _super);
                function Provider() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                Provider.prototype.componentDidMount = function () {
                    this.context(this.props.children, Lifecycle.Mount);
                };
                Provider.prototype.componentDidUpdate = function () {
                    this.context(this.props.children, Lifecycle.Update);
                };
                Provider.prototype.componentWillUnmount = function () {
                    this.context(null, Lifecycle.Unmount);
                };
                Provider.prototype.render = function () {
                    return null;
                };
                return Provider;
            }(react_1.PureComponent)),
            _a.contextType = RenderContext,
            _a; };
    }
    else {
        createProvider = function (_a) {
            var Consumer = _a.Consumer;
            var ProviderImpl = /** @class */ (function (_super) {
                __extends(ProviderImpl, _super);
                function ProviderImpl() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                ProviderImpl.prototype.componentDidMount = function () {
                    this.props.render(this.props.children, Lifecycle.Mount);
                };
                ProviderImpl.prototype.componentDidUpdate = function () {
                    this.props.render(this.props.children, Lifecycle.Update);
                };
                ProviderImpl.prototype.componentWillUnmount = function () {
                    this.props.render(null, Lifecycle.Unmount);
                };
                ProviderImpl.prototype.render = function () {
                    return null;
                };
                return ProviderImpl;
            }(react_1.PureComponent));
            return function (_a) {
                var children = _a.children;
                return (react_1.default.createElement(Consumer, null, function (render) { return (react_1.default.createElement(ProviderImpl, { render: render, children: children })); }));
            };
        };
    }
}
function createJumpgate() {
    var RenderContext = react_1.default.createContext(providerError);
    var NodeContext = react_1.default.createContext(consumerError);
    return {
        Anchor: createAnchor(NodeContext, RenderContext),
        Consumer: createConsumer(NodeContext),
        Provider: createProvider(RenderContext),
    };
}
exports.default = createJumpgate;
